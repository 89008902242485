import React, { useCallback, useEffect, useState } from "react";
import { IonToast } from "@ionic/react";
import PageLayout from "../../layouts/PageLayout";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import http from "axios";
import firebase from "firebase";
import ErrorMessage from "components/ErrorMessage";
import Title from "components/Title";
import Input from "components/Input";
import Button from "components/Button";

interface AddThirdPartyCodesBody {
  type: string;
  codes: string[];
}

interface AddThirdPartyCodesResponse {
  result: string;
}

const AddThirdPartyCodes: React.FC<{}> = () => {
  const types = ["NordVPN 1 Year"];

  const [type, setType] = useState<string>(types[0]);
  const [codeText, setCodeText] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [toastMessage, setToastMessage] = useState<string>("");

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", {
      page: "admin_add_third_party_codes",
    });
  }, []);

  const submit = useCallback(
    (form) => {
      if (form) {
        form.preventDefault();
      }
      firebase.analytics().logEvent("admin_add_third_party_codes", {});
      FirebaseAnalytics.logEvent("admin_add_third_party_codes", {});

      setError("");
      setToastMessage("");

      const codes = codeText.split(/[\r\n]+/);

      const body: AddThirdPartyCodesBody = {
        type,
        codes,
      };

      http
        .post<AddThirdPartyCodesResponse>(`/v1/admin/addthirdpartycodes`, body)
        .then((result) => {
          setCodeText("");
          setToastMessage(result.data.result);
        })
        .catch((e) => {
          setError(e.message);
        });
    },
    [type, codeText]
  );

  return (
    <PageLayout showEnvironment={true}>
      <Title title="Add Third Party Codes" />
      <form onSubmit={(e) => submit(e)}>
        <div className="flex flex-row justify-start items-center w-full gap-3">
          <label
            htmlFor="Code Type Selection"
            className="text-md text-center font-medium my-3 whitespace-nowrap text-gray-500"
          >
            Type
          </label>
          <div className="select-container">
            <select
              id="type"
              data-testid="type-select"
              value={type}
              className="w-full mt-1 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-orange focus:border-orange sm:text-sm rounded-md"
              onChange={(e) => {
                setType(e.currentTarget.value);
              }}
            >
              {types.map((type) => (
                <option
                  data-testid={`codetype-${type}`}
                  key={type}
                  value={type}
                >
                  {type}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="basis-full">
          <Input
            className="my-4"
            id="codeText"
            data-testid="codeText-input"
            label=""
            inputProps={{
              value: codeText,
              onChange: (e: any) => setCodeText(e.target.value),
            }}
            type="text"
            placeholder="Paste codes here (one code per line)"
            useTextArea={true}
          />
        </div>
        <Button type="submit" disabled={codeText === ""} data-testid="submit">
          Submit Codes
        </Button>
        <ErrorMessage isText error={error} />
        <IonToast
          color="success"
          isOpen={!!toastMessage.length}
          onDidDismiss={() => setToastMessage("")}
          message={toastMessage}
          duration={5000}
        />
      </form>
    </PageLayout>
  );
};

export default AddThirdPartyCodes;
