import React, { useCallback, useEffect, useState } from "react";
import { IonToast } from "@ionic/react";
import PageLayout from "../../layouts/PageLayout";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import http from "axios";
import firebase from "firebase";
import ErrorMessage from "components/ErrorMessage";
import Title from "components/Title";
import Input from "components/Input";
import Button from "components/Button";

interface LogLevelsBody {
  api?: boolean;
  uvicorn?: boolean;
  database?: boolean;
  timeout?: number;
}

interface LogLevelsResponse {
  result: string;
}

const ManageLogs: React.FC<{}> = () => {
  const [timeout, setTimeout] = useState<number>(5);
  const [error, setError] = useState<string>("");
  const [toastMessage, setToastMessage] = useState<string>("");

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", {
      page: "admin_manage_logs",
    });
  }, []);

  const handleLogLevelsChange = (
    api?: boolean,
    uvicorn?: boolean,
    database?: boolean
  ) => {
    setError("");
    setToastMessage("");

    const body: LogLevelsBody = {
      api: api,
      uvicorn: uvicorn,
      database: database,
      timeout: timeout,
    };

    http
      .post<LogLevelsResponse>(`/v1/admin/loglevels`, body)
      .then((result) => {
        setToastMessage(result.data.result);
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  return (
    <PageLayout showEnvironment={true}>
      <Title title="Manage Logs" />
      <div className="basis-full">
        <Input
          className="my-4"
          id="timeout"
          data-testid="timeout-input"
          label="Time in minutes before logging reverts to default"
          inputProps={{
            value: timeout,
            onChange: (e: any) =>
              e.target.value >= 0 ? setTimeout(e.target.value) : setTimeout(5),
          }}
          type="text"
          placeholder=""
        />
      </div>
      <div className="flex flex-row gap-3 mt-3">
        <Button
          onClick={() => handleLogLevelsChange(true, undefined, undefined)}
        >
          Log API
        </Button>
        <Button
          onClick={() => handleLogLevelsChange(undefined, true, undefined)}
        >
          Log Uvicorn
        </Button>
        <Button
          onClick={() => handleLogLevelsChange(undefined, undefined, true)}
        >
          Log Database
        </Button>
      </div>
      <div className="flex flex-row gap-3 mt-10">
        <Button
          onClick={() =>
            window.open(
              "https://console.cloud.google.com/logs/query;query=labels.%22k8s-pod%2Fapp%22%3D%22" +
                process.env.REACT_APP_NAMESPACE +
                "-api%22?authuser=1&project=useful-hour-302300",
              "_blank"
            )
          }
        >
          View Logs
        </Button>
      </div>
      <ErrorMessage isText error={error} />
      <IonToast
        color="success"
        isOpen={!!toastMessage.length}
        onDidDismiss={() => setToastMessage("")}
        message={toastMessage}
        duration={5000}
      />
    </PageLayout>
  );
};

export default ManageLogs;
