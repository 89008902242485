import http from "axios";
import MockAdapter from "axios-mock-adapter";
import {
  CHANGE_CONFIGURATION_SERVICE_ROUTE,
  CLAIM_ADAPTER_ROUTE,
  FIND_UNCLAIMED_ADAPTERS_ROUTE,
  ADAPTER_RESET_URL,
  BETA_ROUTE,
} from "../constants";
import { AdapterListItem, Dictionary, FoundAdapter } from "../model";
import { StatusCodes } from "./StatusCodes";
import { createMock } from "./utils";

export type AdapterPostServiceType =
  | "vpn-killswitch"
  | "adblocking"
  | "malware"
  | "streamrelocation"
  | "upnp"
  | "wps"
  | "vpn";

interface ChangeConfigurationServicePayload {
  id: string;
  service: AdapterPostServiceType;
  state: boolean;
}
interface AdapterUpdate {
  state: boolean;
  since: number;
  pending: boolean;
}
/**
 * Change adapter configuration service
 *
 * For updated description
 * See https://privacy-hero.github.io/ph2-apidocs/index-rapidoc.html#post-/v1/adapter/cfg/service
 *
 * For a particular Adapter, change its current service configuration. When called, if the state
 * requested differs from the state currently active in the router, the backend will create a timestamp
 * for the request and send it to the router. It will return that timestamp to the caller.
 * The caller must then poll v1/adapter/cfg at regular intervals until either a reasonable time has passed,
 * or the state changes and/or the state timestamp equals or exceeds the returned request timestamp.
 * When the router applies the state change successfully, the backend will be informed and supplied the
 * timestamp of the request, so that the backend can update the configuration to persistently store the
 * new state of the router.
 */
export function changeConfigurationService(
  payload: ChangeConfigurationServicePayload
) {
  return http.post<AdapterUpdate>(CHANGE_CONFIGURATION_SERVICE_ROUTE, payload);
}
export const mockChangeConfigurationService = createMock<
  AdapterUpdate,
  ChangeConfigurationServicePayload
>(CHANGE_CONFIGURATION_SERVICE_ROUTE, "POST");

/**
 *
 * @param adapterId id: the ID of the adapter, if only one adapters information is being requested.
 * Only adapters associated with a clients account can be returned with this call.
 * IF the id is valid, but the adapter is not associated with this clients account,
 * then that is treated the same as the adapter ID being invalid.
 */
export function getAdapter(adapterId: string) {
  return http.get<AdapterListItem[]>(`${GET_ADAPTER_ROUTE}?id=${adapterId}`);
}
export const GET_ADAPTER_ROUTE = "/v2/adapter/list";
export const mockGetAdapter = (
  mock: MockAdapter,
  adapterId: string,
  status: StatusCodes,
  adapters: AdapterListItem[]
) => {
  return createMock<AdapterListItem[], undefined>(GET_ADAPTER_ROUTE, "GET")(
    mock,
    status,
    adapters,
    undefined,
    `?id=${adapterId}`
  );
};
/**
 * Generates a list of adapters associated with the clients account. Can list a single adapter,
 * or all adapters associated with the account. In the case that a client has no adapters associated,
 * this will be an empty list.
 */
export async function getAdapterList() {
  return http.get<AdapterListItem[]>(GET_ADAPTER_ROUTE);
}
export const mockGetAdapterList = createMock<AdapterListItem[], undefined>(
  GET_ADAPTER_ROUTE,
  "GET"
);

/**
 * When an adapter is first installed, it is not linked to an account.
 * The client must add the adapter to their account,
 * this function will return a list of adapters which are available to be associated
 * but are not in the backend database so they cannot be claimed yet.
 * It will either find all adapters on the same local network as the client connection.
 * Or if that does not work, an adapter with a specific activation code.
 *
 * @param code This is the optional activation code. If not set, the function will look for
 *       all unassociated adapters that share the internet connection of the client making the call.
 * However, IF that fails, this can be set to a known activation code which will cause any adapter with
 * the matching activation code which is not currently associated with an account to be returned.
 * Typically this would be a single Adapter. If no adapters are found, a "404" response will be generated.
 */
export function findUnclaimedAdapters(code?: string) {
  const params = code ? `?code=${code}` : "";
  return http.get<FoundAdapter[]>(`${FIND_UNCLAIMED_ADAPTERS_ROUTE}${params}`);
}
export const mockFindUnclaimedAdaptersWithoutCode = createMock<
  FoundAdapter[],
  undefined
>(FIND_UNCLAIMED_ADAPTERS_ROUTE, "GET");
export const mockFindUnclaimedAdaptersWithCode = (code: string) =>
  createMock<FoundAdapter[], undefined>(
    `${FIND_UNCLAIMED_ADAPTERS_ROUTE}?code=${code}`,
    "GET"
  );

/**
 * Claims the specified adapter and associates it with the clients account.
 *  The Claim can only succeed if the Adapter is not associated with an account.

* This API Call may also be used to replace an existing adapter,
* by specifying the existing adapters AdapterID.
* In which case, the existing adapter will be unassociated from the clients account and
* the newly claimed adapter will replace it in its entirety.
 * @param adapterId The id of the adapter the client wants to claim.
 * @param oldid Allows the claimed adapter to replace an existing adapter on the clients account.
 *     In this instance, the old adapter becomes unassociated with any account,
 *     and the new adapter takes over the configuration, discovered devices and profiles of the old adapter.
 * https://privacy-hero.github.io/ph2-apidocs/index-swui.html#/Adapter%20Management/adapterClaim
 */
export function claimAdapter(adapterId: string, oldAdapterId?: string) {
  return http.post<AdapterListItem>(CLAIM_ADAPTER_ROUTE, {
    id: adapterId,
    oldid: oldAdapterId,
  });
}
export const mockClaimAdapter = createMock<
  AdapterListItem,
  { id: string; oldid?: string }
>(CLAIM_ADAPTER_ROUTE, "POST");

type AdapterWifiConfig = {
  id: string;
} & Partial<{
  enabled: boolean;
  ssid: string;
  key: string;
  guest?: boolean;
  channel?: number;
}>;

export function configureWifi(config: AdapterWifiConfig) {
  return http.post<AdapterWifiConfig & { since: number; pending: number }>(
    "/v2/adapter/cfg/wifi",
    config
  );
}

export interface NameConfig {
  id: string;
  name: string;
}
export interface NameResponse {
  msg: string;
}
export const ADAPTER_CONFIG_NAME_ROUTE = "/v1/adapter/cfg/name";
export function configureName(config: NameConfig) {
  return http.post<NameResponse>(ADAPTER_CONFIG_NAME_ROUTE, config);
}
export const mockConfigureName = createMock<NameResponse, NameConfig>(
  ADAPTER_CONFIG_NAME_ROUTE,
  "POST"
);

export interface DataCapConfig {
  id: string;
  cap: number;
  day: number;
}
export interface DataCapResponse {
  msg: string;
}
export const ADAPTER_CONFIG_DATACAP_ROUTE = "/v1/adapter/cfg/datacap";
export function configureDataCap(config: DataCapConfig) {
  return http.post<DataCapResponse>(ADAPTER_CONFIG_DATACAP_ROUTE, config);
}
export const mockConfigureDataCap = createMock<DataCapResponse, DataCapConfig>(
  ADAPTER_CONFIG_DATACAP_ROUTE,
  "POST"
);
export const ADAPTER_HEALTH_ROUTE = "/v1/adapter/health";
export const ADAPTER_USAGE_ROUTE = "/v1/adapter/usage";
export const SUBSCRIPTIONS_ROUTE = "/v1/client/subscriptions";

export const CHECK_FOR_CLAIMED_ADAPTERS = "/v1/auth/chkrouters";
export type ClaimedAdapter = {
  code: string;
  online: boolean;
  owner: string;
};
export function checkForClaimedAdapters() {
  return http.post<{ routers: Dictionary<ClaimedAdapter> }>(
    CHECK_FOR_CLAIMED_ADAPTERS
  );
}

interface DeviceRestartUpdate {
  id: string;
  reboot: boolean;
}

export const resetAdapter = (update: DeviceRestartUpdate) => {
  return http.post<AdapterListItem>(ADAPTER_RESET_URL, update);
};

interface BetaBody {
  adapter: string;
  enabled: boolean;
}

export function beta(adapter: string, enabled: boolean) {
  const betaBody: BetaBody = {
    adapter,
    enabled,
  };
  return http.post<string>(BETA_ROUTE, betaBody);
}
