import React from "react";
import classNames from "classnames";
import { InputType } from "model";
import { alertCircle, eye, eyeOff } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { useToggle } from "ahooks";

interface Props {
  label: string;
  disabled?: boolean;
  useTextArea?: boolean;
  placeholder: string;
  id: string;
  inputProps: any;
  type: InputType;
  helpText?: string;
  error?: string;
  trailingIcon?: string;
  trailingIconOnPress?: () => void;
  className?: string;
  inputTagClassname?: string;
  suffix?: string;
  onButtonClick?: () => void;
  buttonIcon?: any;
  buttonText?: string;
  buttonProps?: any;
  byDefaultHidden?: boolean;
  textSize?: string;
}
export default function Input({
  label,
  placeholder,
  disabled,
  useTextArea,
  id,
  type,
  helpText,
  inputProps,
  error,
  trailingIcon,
  trailingIconOnPress,
  className,
  inputTagClassname,
  suffix,
  onButtonClick,
  buttonIcon,
  buttonText,
  buttonProps,
  textSize = "sm",
}: Props) {
  return (
    <div className={className}>
      <label
        htmlFor={id}
        className={"block text-" + textSize + " font-medium text-gray-700"}
      >
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm flex">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          {useTextArea ? (
            <textarea
              id={id}
              data-testid={id}
              className={classNames(
                "px-3 py-3",
                "read-only:cursor-default",
                "transition autofill:bg-orange autofill:bg-opacity-30",
                "shadow-sm block w-full text-" +
                  textSize +
                  " rounded-md focus:outline-none",
                inputTagClassname,
                !!error
                  ? "pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
                  : "focus:ring-orange focus:border-orange  border-gray-300 "
              )}
              placeholder={placeholder}
              disabled={disabled}
              {...inputProps}
              aria-invalid={error ? "true" : "false"}
              aria-describedby="error"
            />
          ) : (
            <input
              type={type}
              id={id}
              data-testid={id}
              className={classNames(
                "px-3 py-3",
                "read-only:cursor-default",
                "transition autofill:bg-orange autofill:bg-opacity-30",
                "shadow-sm block w-full text-" +
                  textSize +
                  " rounded-md focus:outline-none",
                inputTagClassname,
                !!error
                  ? "pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
                  : "focus:ring-orange focus:border-orange  border-gray-300 "
              )}
              placeholder={placeholder}
              disabled={disabled}
              {...inputProps}
              aria-invalid={error ? "true" : "false"}
              aria-describedby="error"
            />
          )}
          {suffix ? (
            <div
              className={classNames(
                "absolute inset-y-0 pr-3 flex items-center pointer-events-none",
                error ? "right-6" : "right-0"
              )}
            >
              <span
                className={"text-gray-500 text-" + textSize}
                id="price-currency"
              >
                {suffix}
              </span>
            </div>
          ) : null}
          {error ? (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <IonIcon
                icon={alertCircle}
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          ) : null}
          {trailingIcon ? (
            <div
              className={classNames(
                "absolute inset-y-0 pr-3 flex items-center",
                error ? "right-6" : "right-0",
                !trailingIconOnPress && "cursor-pointer"
              )}
              role="button"
              onClick={
                trailingIconOnPress ? () => trailingIconOnPress() : undefined
              }
            >
              <IonIcon
                icon={trailingIcon}
                className="h-5 w-5 text-gray-400 select-none"
                aria-hidden="true"
              />
            </div>
          ) : null}
        </div>
        {onButtonClick ? (
          <button
            type="button"
            onClick={onButtonClick}
            className={
              "-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-" +
              textSize +
              " font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-orange focus:border-ngeora"
            }
            {...buttonProps}
          >
            <IonIcon
              icon={buttonIcon}
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span className="capitalize">{buttonText}</span>
          </button>
        ) : null}
      </div>
      {helpText ? (
        <p
          className={"capitalize mt-2 text-" + textSize + " text-gray-500"}
          id="email-description"
        >
          {helpText}
        </p>
      ) : null}

      {error
        ? error.split("\n").map(function (item, key) {
            return (
              <p
                className={"mt-2 text-" + textSize + " text-red-600"}
                id="email-error"
              >
                <span key={key}>
                  {item}
                  <br />
                </span>
              </p>
            );
          })
        : null}
    </div>
  );
}

export function PasswordInput({
  byDefaultHidden = true,
  ...props
}: Omit<Props, "type" | "trailingIcon" | "trailingIconOnPress">) {
  const [isPasswordHidden, { toggle: togglePasswordHidden }] = useToggle(
    byDefaultHidden
  );
  return (
    <Input
      {...props}
      type={isPasswordHidden ? "password" : "text"}
      trailingIcon={isPasswordHidden ? eye : eyeOff}
      trailingIconOnPress={togglePasswordHidden}
    />
  );
}
