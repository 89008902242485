import React, { useMemo } from "react";

import { DeviceDetails } from "../../../model";
import { ListRow } from "../../../components";
import { formatDistance } from "date-fns";
import { useTranslation } from "react-i18next";

interface Props {
  device: DeviceDetails;
}

const NerdStuffComponent: React.FC<Props> = ({ device }) => {
  const { t } = useTranslation();
  const lastSeenOnlineTime = useMemo(
    () => formatDistance(device.online_since, new Date()),
    [device.online_since]
  );
  return (
    <div>
      <div className="module-large">
        <ul className="data-list">
          <ListRow text={t("deviceOverview:nerd.ip")}>
            <p>{device.ip.ip4}</p>
          </ListRow>
          <ListRow text={t("deviceOverview:nerd.mac")}>
            <p>{device.mac}</p>
          </ListRow>
          <ListRow text={t("deviceOverview:nerd.online")}>
            <p>
              {device.online
                ? "yes"
                : `${t(
                    "deviceOverview:nerd.lastSeen"
                  )} ${lastSeenOnlineTime} ago`}
            </p>
          </ListRow>
        </ul>
      </div>
    </div>
  );
};
export default NerdStuffComponent;
