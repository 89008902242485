
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "header": {
    "endDescription": "You are about to end your session viewing the account of this user. Any unsaved changes will be lost.",
    "modalTitle": "End impersonation",
    "ok": "OK",
    "cancel": "Cancel",
    "endImpersonation": "End Impersonation"
  }
}
