import React, { useEffect, useState } from "react";
import { IonIcon, IonToast } from "@ionic/react";
import PageLayout from "../../layouts/PageLayout";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import { useHistory } from "react-router";
import http from "axios";
import { useDispatch } from "react-redux";
import { Dispatch } from "../../store";
import ErrorMessage from "components/ErrorMessage";
import Title from "components/Title";
import Input, { PasswordInput } from "components/Input";
import Button from "components/Button";
import BetterModal from "components/BetterModal";
import { FirmwareStatus, UserToken } from "../../model";
import { checkmarkCircle, removeCircle } from "ionicons/icons";

type StatesDict = {
  [name: string]: [since: number, state: any];
};

interface HWType {
  type: string;
  name: string;
  description: string;
}

interface DeviceRowItem {
  id: string;
  mac: string;
  profile: string;
  connection: string;
  guest: boolean;
  needs_identification: boolean;
  states: StatesDict;
}

interface ProfileRowItem {
  id: string;
  name: string;
  icon: string;
  system: boolean;
  created: number;
  updated: number;
  states: StatesDict;
}

interface AdapterRowItem {
  id: string;
  mac: string;
  type: string;
  name: string;
  registered: number;
  claimed: number | null;
  claimed_ip: string | null;
  updated: number;
  online: boolean;
  online_at: number;
  online_srv_ip: string | null;
  online_srv_id: string | null;
  online_chk: number;
  claimed_codes: string[];
  activation_code: string;
  hw_type: HWType;
  fw_status: FirmwareStatus | null;
  states: StatesDict;
  profiles: ProfileRowItem[];
  devices: DeviceRowItem[];
}

interface LookupAccountResponse {
  id: string;
  email: string;
  registered: number;
  registered_from: string;
  updated: number;
  updated_from: string;
  stripe_customer_id: string | null;
  adapters: AdapterRowItem[];
}

interface StateResponse {
  result: string;
  since: number;
  state: string;
}

interface UIState {
  since: number;
  savedState: string;
  currentState: string;
  currentStateIsValid: boolean;
}

type UIStatesDict = {
  [name: string]: UIState;
};

type StateType = "adapter" | "profile" | "device";

///////////////////////////////////////////////////////////////
//   MAIN COMPONENT
///////////////////////////////////////////////////////////////

const ManageAccounts: React.FC<{}> = () => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  const [lookupString, setLookupString] = useState<string>("");
  const [foundAccount, setFoundAccount] = useState<LookupAccountResponse>();
  const [lookupError, setLookupError] = useState<string>("");
  const [toastMessage, setToastMessage] = useState<string>("");
  const [impersonateModalOpen, setImpersonateModalOpen] = useState<boolean>(
    false
  );
  const [selectedAdapter, setSelectedAdapter] = useState<AdapterRowItem>();
  const [selectedProfile, setSelectedProfile] = useState<ProfileRowItem>();
  const [selectedDevice, setSelectedDevice] = useState<DeviceRowItem>();
  const [adapterStates, setAdapterStates] = useState<UIStatesDict>({});
  const [profileStates, setProfileStates] = useState<UIStatesDict>({});
  const [deviceStates, setDeviceStates] = useState<UIStatesDict>({});
  const [echoModalOpen, setEchoModalOpen] = useState<boolean>(false);
  const [deleteAdapterModalOpen, setDeleteAdapterModalOpen] = useState<boolean>(
    false
  );
  const [deleteDevicesModalOpen, setDeleteDevicesModalOpen] = useState<boolean>(
    false
  );
  const [deleteProfileModalOpen, setDeleteProfileModalOpen] = useState<boolean>(
    false
  );
  const [deleteDeviceModalOpen, setDeleteDeviceModalOpen] = useState<boolean>(
    false
  );

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "admin_manage_accounts" });
  }, []);

  useEffect(() => {
    setSelectedProfile(undefined);
    setSelectedDevice(undefined);
    if (selectedAdapter !== undefined) {
      let states: UIStatesDict = {};
      Object.entries(selectedAdapter.states).forEach(([name, value]) => {
        const jsonString = JSON.stringify(value[1]);
        states[name] = {
          since: value[0],
          savedState: jsonString,
          currentState: jsonString,
          currentStateIsValid: true,
        };
      });
      setAdapterStates(states);
    }
  }, [selectedAdapter]);

  useEffect(() => {
    if (selectedProfile !== undefined) {
      let states: UIStatesDict = {};
      Object.entries(selectedProfile.states).forEach(([name, value]) => {
        const jsonString = JSON.stringify(value[1]);
        states[name] = {
          since: value[0],
          savedState: jsonString,
          currentState: jsonString,
          currentStateIsValid: true,
        };
      });
      setProfileStates(states);
    }
  }, [selectedProfile]);

  useEffect(() => {
    if (selectedDevice !== undefined) {
      let states: UIStatesDict = {};
      Object.entries(selectedDevice.states).forEach(([name, value]) => {
        const jsonString = JSON.stringify(value[1]);
        states[name] = {
          since: value[0],
          savedState: jsonString,
          currentState: jsonString,
          currentStateIsValid: true,
        };
      });
      setDeviceStates(states);
    }
  }, [selectedDevice]);

  const handleLookupAccount = () => {
    setFoundAccount(undefined);
    setSelectedAdapter(undefined);
    setLookupError("");
    setToastMessage("");

    const body = {
      lookup_string: lookupString,
    };

    http
      .post<LookupAccountResponse>(`/v1/admin/lookupaccount`, body)
      .then((result) => {
        setFoundAccount(result.data);
      })
      .catch((error) => {
        setLookupError(
          error.response
            ? error.response.data.detail
            : "Error looking up account: " + error.message
        );
      });
  };

  const handleImpersonateUser = (email: string, admin_password: string) => {
    const body = {
      id: email,
      pwd: admin_password,
    };

    http
      .post<UserToken>(`/v1/auth/login`, body)
      .then(({ data }) => {
        dispatch.authentication.impersonateUser({ ...data, email: email });
        history.push("/home");
      })
      .catch((error) => {
        setToastMessage(
          error.response
            ? error.response.data.detail
            : "Error in impersonate user: " + error.message
        );
      });
  };

  const handleCallHome = (adapter_id: string) => {
    setToastMessage("");

    const body = {
      adapter: adapter_id,
    };

    http
      .post<string>(`/v1/admin/callhome`, body)
      .then((result) => {
        setToastMessage(result.data);
      })
      .catch((error) => {
        setToastMessage(
          error.response
            ? error.response.data.detail
            : "Error in call home: " + error.message
        );
      });
  };

  const handleReboot = (adapter_id: string) => {
    setToastMessage("");

    const body = {
      adapter: adapter_id,
    };

    http
      .post<string>(`/v1/admin/reboot`, body)
      .then((result) => {
        setToastMessage(result.data);
      })
      .catch((error) => {
        setToastMessage(
          error.response
            ? error.response.data.detail
            : "Error in reboot: " + error.message
        );
      });
  };

  const handleRestartPhero = (adapter_id: string) => {
    setToastMessage("");

    const body = {
      adapter: adapter_id,
      state_name: "restart",
      state: true,
    };

    http
      .post<string>(`/v1/admin/adminstate`, body)
      .then((result) => {
        setToastMessage(result.data);
      })
      .catch((error) => {
        setToastMessage(
          error.response
            ? error.response.data.detail
            : "Error in restart phero: " + error.message
        );
      });
  };

  const handleEcho = (adapter_id: string, message: string) => {
    setToastMessage("");

    const body = {
      adapter: adapter_id,
      message: message,
    };

    http
      .post<string>(`/v1/admin/echo`, body)
      .then((result) => {
        setToastMessage(result.data);
        setEchoModalOpen(false);
      })
      .catch((error) => {
        setToastMessage(
          error.response
            ? error.response.data.detail
            : "Error in echo: " + error.message
        );
      });
  };

  const handleEnableLogging = (adapter_id: string) => {
    setToastMessage("");

    const body = {
      adapter: adapter_id,
      state_name: "log",
      state: true,
    };

    http
      .post<string>(`/v1/admin/adminstate`, body)
      .then((result) => {
        setToastMessage(result.data);
      })
      .catch((error) => {
        setToastMessage(
          error.response
            ? error.response.data.detail
            : "Error in enable logging: " + error.message
        );
      });
  };

  const handleDeleteDevices = (adapter_id: string) => {
    setToastMessage("");

    const body = {
      adapter: adapter_id, //TODO: make this support one device
    };

    http
      .post<string>(`/v1/admin/deletedevices`, body)
      .then((result) => {
        setToastMessage(result.data);
        //handleLookupAccount();
      })
      .catch((error) => {
        setToastMessage(
          error.response
            ? error.response.data.detail
            : "Error in delete devices: " + error.message
        );
      });
  };

  const handleDeleteProfiles = (profile_id: string) => {
    setToastMessage("");

    const body = {
      id: profile_id, //TODO: implement this endpoint
    };

    http
      .post<string>(`/v1/admin/deleteprofiles`, body)
      .then((result) => {
        setToastMessage(result.data);
        //handleLookupAccount();
      })
      .catch((error) => {
        setToastMessage(
          error.response
            ? error.response.data.detail
            : "Error in delete profile: " + error.message
        );
      });
  };

  const handleDeleteAdapter = (adapter_id: string) => {
    setToastMessage("");

    const body = {
      adapter: adapter_id,
    };

    http
      .post<string>(`/v1/admin/deleteadapter`, body)
      .then((result) => {
        setToastMessage(result.data);
        //handleLookupAccount();
        //setDeleteAdapterModalOpen(false);
      })
      .catch((error) => {
        setToastMessage(
          error.response
            ? error.response.data.detail
            : "Error in delete adapter: " + error.message
        );
      });
  };

  const handleSaveState = (
    target: StateType,
    id: string,
    state_name: string,
    state: string | null
  ) => {
    setToastMessage("");

    const body = {
      id: id,
      state_name: state_name,
      state: state,
    };

    http
      .post<StateResponse>(`/v1/admin/state`, body)
      .then((result) => {
        setToastMessage(result.data.result);
        if (target === "adapter") {
          let newAdapterState = {
            ...adapterStates[state_name],
            since: result.data.since,
            savedState: result.data.state,
            currentState: result.data.state,
          };
          setAdapterStates({ ...adapterStates, [state_name]: newAdapterState });
        } else if (target === "profile") {
          let newProfileState = {
            ...profileStates[state_name],
            since: result.data.since,
            savedState: result.data.state,
            currentState: result.data.state,
          };
          setProfileStates({ ...profileStates, [state_name]: newProfileState });
        } else if (target === "device") {
          let newDeviceState = {
            ...deviceStates[state_name],
            since: result.data.since,
            savedState: result.data.state,
            currentState: result.data.state,
          };
          setDeviceStates({ ...deviceStates, [state_name]: newDeviceState });
        }
      })
      .catch((error) => {
        setToastMessage(
          error.response
            ? error.response.data.detail
            : "Error in save " + target + " state: " + error.message
        );
      });
  };

  const handleStateChange = (
    target: StateType,
    state_name: string,
    state: string
  ) => {
    let newState: UIState;

    if (target === "adapter")
      newState = { ...adapterStates[state_name], currentState: state };
    else if (target === "profile")
      newState = { ...profileStates[state_name], currentState: state };
    else if (target === "device")
      newState = { ...deviceStates[state_name], currentState: state };
    else return;

    if (state === "null") {
      newState.currentStateIsValid = newState.savedState === "null";
    } else {
      try {
        JSON.parse(state);
        newState.currentStateIsValid = true;
      } catch (e) {
        newState.currentStateIsValid = false;
      }
    }

    if (target === "adapter")
      setAdapterStates({ ...adapterStates, [state_name]: newState });
    else if (target === "profile")
      setProfileStates({ ...profileStates, [state_name]: newState });
    else if (target === "device")
      setDeviceStates({ ...deviceStates, [state_name]: newState });
  };

  return (
    <PageLayout showEnvironment={true}>
      <Title title="Manage Accounts" />
      <div className="flex flex-row items-center my-2 gap-3">
        <Input
          className="w-full"
          id="lookup_string"
          label=""
          placeholder="Email, Account ID, Stripe ID, Adapter ID, or MAC"
          type="text"
          inputProps={{
            value: lookupString,
            onChange: (e: any) => setLookupString(e.target.value),
          }}
        />
        <Button
          className="w-full"
          disabled={lookupString === ""}
          onClick={() => handleLookupAccount()}
        >
          Lookup Account
        </Button>
      </div>
      <ErrorMessage isText error={lookupError} />

      {foundAccount && (
        <>
          <div className="bg-gray-100">
            {foundAccount.id ? (
              <>
                <p className="my-5 text-md font-semibold">
                  Account details for: {foundAccount.email}
                </p>
                <table>
                  <thead className="text-left text-sm">
                    <tr key="accounts_header">
                      <th className="p-1">ID</th>
                      <th className="p-1">Email</th>
                      <th className="p-1">Registered</th>
                      {/*<th className="p-1">Registered From</th>*/}
                      <th className="p-1">Updated</th>
                      {/*<th className="p-1">Updated From</th>*/}
                      <th className="p-1">Stripe Customer ID</th>
                    </tr>
                  </thead>
                  <tbody className="text-xs">
                    <tr key={"account_row_" + foundAccount.id}>
                      <td className="p-1">{foundAccount.id}</td>
                      <td className="p-1">{foundAccount.email}</td>
                      <td className="p-1">
                        {new Date(foundAccount.registered).toLocaleDateString()}
                      </td>
                      {/*<td className="p-1">{foundAccount.registered_from}</td>*/}
                      <td className="p-1">
                        {new Date(foundAccount.updated).toLocaleDateString()}
                      </td>
                      {/*<td className="p-1">{foundAccount.updated_from}</td>*/}
                      <td className="p-1">
                        {foundAccount.stripe_customer_id || "NULL"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="flex flex-row gap-3 mt-3">
                  <Button
                    size="small"
                    onClick={() => setImpersonateModalOpen(true)}
                  >
                    Impersonate
                  </Button>
                  <Button
                    size="small"
                    onClick={() =>
                      window.open(
                        "https://dashboard.stripe.com/customers/" +
                          foundAccount.stripe_customer_id,
                        "_blank"
                      )
                    }
                    disabled={foundAccount.stripe_customer_id === null}
                  >
                    Open Stripe
                  </Button>
                </div>
                <BetterModal
                  isOpen={impersonateModalOpen}
                  onClose={() => setImpersonateModalOpen(false)}
                >
                  <ImpersonateModalContent
                    email={foundAccount.email}
                    onOk={handleImpersonateUser}
                    onClose={() => setImpersonateModalOpen(false)}
                  />
                </BetterModal>
              </>
            ) : (
              <p className="my-5 text-md font-semibold">
                No account details (unclaimed adapter)
              </p>
            )}
          </div>

          <div className="bg-gray-100">
            {foundAccount.adapters.length > 0 ? (
              <>
                <p className="my-5 text-md font-semibold">Adapters:</p>
                <table>
                  <thead className="text-left text-sm">
                    <tr key="adapters_header">
                      <th className="p-1">ID</th>
                      <th className="p-1">MAC</th>
                      <th className="p-1">Type</th>
                      <th className="p-1">Name</th>
                      {/*<th className="p-1">Registered</th>*/}
                      <th className="p-1">Claimed</th>
                      {/*<th className="p-1">Claimed IP</th>*/}
                      <th className="p-1">Updated</th>
                      <th className="p-1">Online</th>
                      {/*<th className="p-1">Online At</th>*/}
                      {/*<th className="p-1">Online Srv IP</th>*/}
                      {/*<th className="p-1">Online Srv ID</th>*/}
                      <th className="p-1">Checked</th>
                      <th className="p-1">Profiles</th>
                      <th className="p-1">Devices</th>
                    </tr>
                  </thead>
                  <tbody className="text-xs">
                    {foundAccount.adapters.map((adapter) => (
                      <tr
                        key={"adapter_row_" + adapter.id}
                        style={{
                          backgroundColor:
                            adapter.id === selectedAdapter?.id
                              ? "palegreen"
                              : "transparent",
                        }}
                        onClick={() => setSelectedAdapter(adapter)}
                      >
                        <td className="p-1">{adapter.id}</td>
                        <td className="p-1">{adapter.mac}</td>
                        <td className="p-1">{adapter.type}</td>
                        <td className="p-1">{adapter.name}</td>
                        {/*<td className="p-1">{new Date(adapter.registered).toLocaleDateString()}</td>*/}
                        <td className="p-1">
                          {adapter.claimed
                            ? new Date(adapter.claimed).toLocaleDateString()
                            : "NULL"}
                        </td>
                        {/*<td className="p-1">{adapter.claimed_ip || "NULL"}</td>*/}
                        <td className="p-1">
                          {new Date(adapter.updated).toLocaleDateString()}
                        </td>
                        <td className="p-1">{adapter.online.toString()}</td>
                        {/*<td className="p-1">{new Date(adapter.online_at).toLocaleDateString()}</td>*/}
                        {/*<td className="p-1">{adapter.online_srv_ip || "NULL"}</td>*/}
                        {/*<td className="p-1">{adapter.online_srv_id || "NULL"}</td>*/}
                        <td className="p-1">
                          {new Date(adapter.online_chk).toLocaleDateString()}
                        </td>
                        <td className="p-1">{adapter.profiles.length}</td>
                        <td className="p-1">{adapter.devices.length}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {selectedAdapter && (
                  <>
                    <p className="my-5 text-md font-semibold">
                      Adapter Details:
                    </p>
                    <div className="flex flex-col gap-1">
                      <p>
                        Claimed Codes:{" "}
                        {selectedAdapter.claimed_codes?.toString() || "None"}
                      </p>
                      <p>Activation Code: {selectedAdapter.activation_code}</p>
                      <p>
                        Hardware: Type {selectedAdapter.hw_type.type} -{" "}
                        {selectedAdapter.hw_type.name} [
                        {selectedAdapter.hw_type.description}]
                      </p>
                      {selectedAdapter.fw_status ? (
                        <p>
                          OS Firmware:{" "}
                          {selectedAdapter.fw_status.fw.os ? (
                            <p className="text-xs">
                              {"Updating: "}
                              {selectedAdapter.fw_status.fw.os.update.toString()}
                              {" ("}
                              {selectedAdapter.fw_status.fw.os.pct}
                              {"%)"}
                              <br />
                              {"Version: "}
                              {selectedAdapter.fw_status.fw.os.version}
                            </p>
                          ) : (
                            <p className="text-xs">NULL</p>
                          )}
                          <br />
                          Recovery Firmware:{" "}
                          {selectedAdapter.fw_status.fw.recovery ? (
                            <p className="text-xs">
                              {"Updating: "}
                              {selectedAdapter.fw_status.fw.recovery.update.toString()}
                              {" ("}
                              {selectedAdapter.fw_status.fw.recovery.pct}
                              {"%)"}
                              <br />
                              {"Version: "}
                              {selectedAdapter.fw_status.fw.recovery.version}
                            </p>
                          ) : (
                            <p className="text-xs">NULL</p>
                          )}
                          <br />
                          Manual Reboot:{" "}
                          {selectedAdapter.fw_status.manual_reboot?.toString() ||
                            "NULL"}
                        </p>
                      ) : (
                        <p>Router Firmware Status is NULL</p>
                      )}
                    </div>
                    <div className="flex flex-row gap-3 mt-3">
                      <Button
                        size="small"
                        onClick={() => handleCallHome(selectedAdapter.id)}
                        disabled={!selectedAdapter.online}
                      >
                        Call Home
                      </Button>
                      <Button
                        size="small"
                        onClick={() => handleReboot(selectedAdapter.id)}
                        disabled={!selectedAdapter.online}
                      >
                        Reboot Adapter
                      </Button>
                      <Button
                        size="small"
                        onClick={() => handleRestartPhero(selectedAdapter.id)}
                        disabled={!selectedAdapter.online}
                      >
                        Restart Phero
                      </Button>
                      <Button
                        size="small"
                        onClick={() => setEchoModalOpen(true)}
                        disabled={!selectedAdapter.online}
                      >
                        Send Raw Message
                      </Button>
                      <Button
                        size="small"
                        onClick={() => handleEnableLogging(selectedAdapter.id)}
                      >
                        Enable Logging
                      </Button>
                      <Button
                        size="small"
                        onClick={() => setDeleteDevicesModalOpen(true)}
                      >
                        Delete Devices
                      </Button>
                      <Button
                        size="small"
                        onClick={() => setDeleteAdapterModalOpen(true)}
                        disabled={selectedAdapter.online}
                      >
                        Delete Adapter
                      </Button>
                    </div>
                    <p className="my-5 text-md font-semibold">
                      Adapter States:
                    </p>
                    <StateTable
                      states={adapterStates}
                      onStateChange={(state_name, state) =>
                        handleStateChange("adapter", state_name, state)
                      }
                      onSaveState={(state_name, state) =>
                        handleSaveState(
                          "adapter",
                          selectedAdapter.id,
                          state_name,
                          state
                        )
                      }
                      onDeleteState={(state_name) =>
                        handleSaveState(
                          "adapter",
                          selectedAdapter.id,
                          state_name,
                          null
                        )
                      }
                    />
                    <BetterModal
                      isOpen={deleteAdapterModalOpen}
                      onClose={() => setDeleteAdapterModalOpen(false)}
                    >
                      <DeleteModalContent
                        type="Adapter"
                        text="This action is irreversible and will also delete all stripe subscriptions, devices, profiles, and data usage associated with the adapter."
                        id={selectedAdapter.id}
                        onOk={handleDeleteAdapter}
                        onClose={() => setDeleteAdapterModalOpen(false)}
                      />
                    </BetterModal>
                    <BetterModal
                      isOpen={deleteDevicesModalOpen}
                      onClose={() => setDeleteDevicesModalOpen(false)}
                    >
                      <DeleteModalContent
                        type="All Devices"
                        text="This action is irreversible and will also delete all data usage associated with the devices."
                        id={selectedAdapter.id}
                        onOk={handleDeleteDevices}
                        onClose={() => setDeleteDevicesModalOpen(false)}
                      />
                    </BetterModal>
                    <BetterModal
                      isOpen={echoModalOpen}
                      onClose={() => setEchoModalOpen(false)}
                    >
                      <EchoModalContent
                        adapterId={selectedAdapter.id}
                        onOk={handleEcho}
                        onClose={() => setEchoModalOpen(false)}
                      />
                    </BetterModal>
                  </>
                )}
              </>
            ) : (
              <p className="my-5 text-md font-semibold">No Adapters</p>
            )}
          </div>

          {selectedAdapter && (
            <>
              <div className="bg-gray-100">
                {selectedAdapter.profiles.length > 0 ? (
                  <>
                    <p className="my-5 text-md font-semibold">Profiles:</p>
                    <table>
                      <thead className="text-left text-sm">
                        <tr key="profiles_header">
                          <th className="p-1">ID</th>
                          <th className="p-1">Name</th>
                          <th className="p-1">Icon</th>
                          <th className="p-1">System</th>
                          <th className="p-1">Created</th>
                          <th className="p-1">Updated</th>
                        </tr>
                      </thead>
                      <tbody className="text-xs">
                        {selectedAdapter.profiles.map((profile) => (
                          <tr
                            key={"profile_row_" + profile.id}
                            style={{
                              backgroundColor:
                                profile.id === selectedProfile?.id
                                  ? "palegreen"
                                  : "transparent",
                            }}
                            onClick={() => setSelectedProfile(profile)}
                          >
                            <td className="p-1">{profile.id}</td>
                            <td className="p-1">{profile.name}</td>
                            <td className="p-1">{profile.icon}</td>
                            <td className="p-1">{profile.system.toString()}</td>
                            <td className="p-1">
                              {new Date(profile.created).toLocaleDateString()}
                            </td>
                            <td className="p-1">
                              {new Date(profile.updated).toLocaleDateString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {selectedProfile && (
                      <>
                        <div className="flex flex-row gap-3 mt-3">
                          <Button
                            size="small"
                            onClick={() => setDeleteProfileModalOpen(true)}
                          >
                            Delete Profile
                          </Button>
                        </div>
                        <p className="my-5 text-md font-semibold">
                          Profile States:
                        </p>
                        <StateTable
                          states={profileStates}
                          onStateChange={(state_name, state) =>
                            handleStateChange("profile", state_name, state)
                          }
                          onSaveState={(state_name, state) =>
                            handleSaveState(
                              "profile",
                              selectedProfile.id,
                              state_name,
                              state
                            )
                          }
                          onDeleteState={(state_name) =>
                            handleSaveState(
                              "profile",
                              selectedProfile.id,
                              state_name,
                              null
                            )
                          }
                        />
                        <BetterModal
                          isOpen={deleteProfileModalOpen}
                          onClose={() => setDeleteProfileModalOpen(false)}
                        >
                          <DeleteModalContent
                            type="Profile"
                            text="This action is irreversible and will also delete all devices and data usage associated with the profile."
                            id={selectedProfile.id}
                            onOk={handleDeleteProfiles}
                            onClose={() => setDeleteProfileModalOpen(false)}
                          />
                        </BetterModal>
                      </>
                    )}
                  </>
                ) : (
                  <p className="my-5 text-md font-semibold">No Profiles</p>
                )}
              </div>
            </>
          )}

          {selectedAdapter && (
            <>
              <div className="bg-gray-100">
                {selectedAdapter.devices.length > 0 ? (
                  <>
                    <p className="my-5 text-md font-semibold">Devices:</p>
                    <table>
                      <thead className="text-left text-sm">
                        <tr key="devices_header">
                          <th className="p-1">ID</th>
                          <th className="p-1">MAC</th>
                          <th className="p-1">Profile</th>
                          <th className="p-1">Connection</th>
                          <th className="p-1">Guest</th>
                          <th className="p-1">Needs Ident</th>
                        </tr>
                      </thead>
                      <tbody className="text-xs">
                        {selectedAdapter.devices.map((device) => (
                          <tr
                            key={"device_row_" + device.id}
                            style={{
                              backgroundColor:
                                device.id === selectedDevice?.id
                                  ? "palegreen"
                                  : "transparent",
                            }}
                            onClick={() => setSelectedDevice(device)}
                          >
                            <td className="p-1">{device.id}</td>
                            <td className="p-1">{device.mac}</td>
                            <td className="p-1">{device.profile}</td>
                            <td className="p-1">{device.connection}</td>
                            <td className="p-1">{device.guest.toString()}</td>
                            <td className="p-1">
                              {device.needs_identification.toString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {selectedDevice && (
                      <>
                        <div className="flex flex-row gap-3 mt-3">
                          <Button
                            size="small"
                            onClick={() => setDeleteDeviceModalOpen(true)}
                          >
                            Delete Device
                          </Button>
                        </div>
                        <p className="my-5 text-md font-semibold">
                          Device States:
                        </p>
                        <StateTable
                          states={deviceStates}
                          onStateChange={(state_name, state) =>
                            handleStateChange("device", state_name, state)
                          }
                          onSaveState={(state_name, state) =>
                            handleSaveState(
                              "device",
                              selectedDevice.id,
                              state_name,
                              state
                            )
                          }
                          onDeleteState={(state_name) =>
                            handleSaveState(
                              "device",
                              selectedDevice.id,
                              state_name,
                              null
                            )
                          }
                        />
                        <BetterModal
                          isOpen={deleteDeviceModalOpen}
                          onClose={() => setDeleteDeviceModalOpen(false)}
                        >
                          <DeleteModalContent
                            type="Device"
                            text="This action is irreversible and will also delete all data usage associated with the device."
                            id={selectedDevice.id}
                            onOk={handleDeleteDevices}
                            onClose={() => setDeleteDeviceModalOpen(false)}
                          />
                        </BetterModal>
                      </>
                    )}
                  </>
                ) : (
                  <p className="my-5 text-md font-semibold">No Devices</p>
                )}
              </div>
            </>
          )}
        </>
      )}

      <IonToast
        color="success"
        isOpen={!!toastMessage.length}
        onDidDismiss={() => setToastMessage("")}
        message={toastMessage}
        duration={5000}
      />
    </PageLayout>
  );
};

export default ManageAccounts;

///////////////////////////////////////////////////////////////
//   STATE TABLE
///////////////////////////////////////////////////////////////

interface StateTableProps {
  states: UIStatesDict;
  onStateChange(state_name: string, state: string): void;
  onSaveState(state_name: string, state: string): void;
  onDeleteState(state_name: string): void;
}

const StateTable: React.FC<StateTableProps> = ({
  states,
  onStateChange,
  onSaveState,
  onDeleteState,
}) => {
  return (
    <table>
      <thead className="text-left text-sm">
        <tr key="adapter_states_header">
          <th className="p-1">Name</th>
          <th className="p-1">Since</th>
          <th className="p-1">State</th>
          <th className="p-1"></th>
        </tr>
      </thead>
      <tbody className="text-xs">
        {Object.entries(states).map(([name, state]) => (
          <tr key={"adapter_state_" + name}>
            <td className="p-1">{name}</td>
            <td className="p-1">
              {state.since === 0
                ? "(default)"
                : new Date(state.since).toLocaleDateString()}
            </td>
            <td className="p-1 w-full">
              <Input
                id={"adapter_state_" + name}
                label=""
                placeholder=""
                type="text"
                textSize="xs"
                error={state.currentStateIsValid ? "" : "Invalid value"}
                inputProps={{
                  value: state.currentState,
                  onChange: (e: any) => onStateChange(name, e.target.value),
                }}
              />
            </td>
            <td className="p-1 align-bottom">
              <div className="flex flex-row gap-1">
                <Button
                  size="small"
                  variant="white"
                  onClick={() => onSaveState(name, state.currentState)}
                  disabled={
                    !state.currentStateIsValid ||
                    state.savedState === state.currentState
                  }
                >
                  <IonIcon
                    icon={checkmarkCircle}
                    className="h-6 w-6 text-green-600"
                  />
                </Button>
                <Button
                  size="small"
                  variant="white"
                  onClick={() => onDeleteState(name)}
                  disabled={state.since === 0}
                >
                  <IonIcon
                    icon={removeCircle}
                    className="h-6 w-6 text-red-500"
                  />
                </Button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

///////////////////////////////////////////////////////////////
//   IMPERSONATE USER MODAL
///////////////////////////////////////////////////////////////

interface ImpersonateModalContentProps {
  email: string;
  onOk(email: string, admin_password: string): void;
  onClose(): void;
}

const ImpersonateModalContent: React.FC<ImpersonateModalContentProps> = ({
  email,
  onOk,
  onClose,
}) => {
  const [adminPassword, setAdminPassword] = useState<string>("");

  return (
    <>
      <div className="pb-1 border-b border-solid border-gray-200 text-lg font-semibold">
        Impersonate {email}
      </div>
      <div className="flex flex-col pt-5 gap-3">
        <p className="text-sm">
          You are about to begin viewing this user's account as if you were
          them.
        </p>
        <PasswordInput
          id="password"
          label=""
          placeholder="Admin Password"
          inputProps={{
            value: adminPassword,
            onChange: (e: any) => setAdminPassword(e.target.value),
          }}
        />
      </div>
      <div className="flex flex-row justify-center pt-5 gap-3">
        <Button size="small" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          size="small"
          onClick={() => onOk(email, adminPassword)}
          disabled={adminPassword === ""}
        >
          Impersonate
        </Button>
      </div>
    </>
  );
};

///////////////////////////////////////////////////////////////
//   ECHO MODAL
///////////////////////////////////////////////////////////////

interface EchoModalContentProps {
  adapterId: string;
  onOk(adapter_id: string, message: string): void;
  onClose(): void;
}

const EchoModalContent: React.FC<EchoModalContentProps> = ({
  adapterId,
  onOk,
  onClose,
}) => {
  const [message, setMessage] = useState<string>("");
  const [messageIsValid, setMessageIsValid] = useState<boolean>(false);

  const messageChangeHandler = (message: string) => {
    setMessage(message);

    try {
      var o = JSON.parse(message);
      setMessageIsValid(o && typeof o === "object");
    } catch (e) {
      setMessageIsValid(false);
    }
  };

  return (
    <>
      <div className="pb-1 border-b border-solid border-gray-200 text-lg font-semibold">
        Send raw message to adapter {adapterId}
      </div>
      <div className="flex flex-col pt-5 gap-3">
        <p className="text-sm">
          Message body in JSON format. Timestamp field will be added
          automatically.
        </p>
        <Input
          id="message"
          label=""
          placeholder='{"message": "admin-state-change", "services": [{"service": "restart", "state": true}]}'
          type="text"
          inputProps={{
            value: message,
            onChange: (e: any) => messageChangeHandler(e.target.value),
          }}
          useTextArea={true}
        />
      </div>
      <div className="flex flex-row justify-center pt-5 gap-3">
        <Button size="small" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          size="small"
          onClick={() => onOk(adapterId, message)}
          disabled={!messageIsValid}
        >
          Send Message
        </Button>
      </div>
    </>
  );
};

///////////////////////////////////////////////////////////////
//   DELETE MODAL
///////////////////////////////////////////////////////////////

interface DeleteModalContentProps {
  type: string;
  text: string;
  id: string;
  onOk(adapter_id: string): void;
  onClose(): void;
}

const DeleteModalContent: React.FC<DeleteModalContentProps> = ({
  type,
  text,
  id,
  onOk,
  onClose,
}) => {
  return (
    <>
      <div className="pb-1 border-b border-solid border-gray-200 text-lg font-semibold">
        Delete {type} {id}
      </div>
      <div className="flex flex-col pt-5 gap-3">
        <p className="text-sm">{text}</p>
      </div>
      <div className="flex flex-row justify-center pt-5 gap-3">
        <Button size="small" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button size="small" onClick={() => onOk(id)}>
          Delete {type}
        </Button>
      </div>
    </>
  );
};
