import React, { useCallback, useEffect, useState } from "react";
import { IonToast } from "@ionic/react";
import PageLayout from "../../layouts/PageLayout";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import http from "axios";
import firebase from "firebase";
import ErrorMessage from "components/ErrorMessage";
import Title from "components/Title";
import Input from "components/Input";
import Button from "components/Button";

interface BulkEmailBody {
  template_name: string;
  emails: string[];
}

interface BulkEmailResponse {
  result: string;
}

const BulkEmail: React.FC<{}> = () => {
  const [templateName, setTemplateName] = useState<string>("");
  const [emailText, setEmailText] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [toastMessage, setToastMessage] = useState<string>("");

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", {
      page: "admin_bulk_email",
    });
  }, []);

  const submit = useCallback(
    (form) => {
      if (form) {
        form.preventDefault();
      }
      firebase.analytics().logEvent("admin_bulk_email", {});
      FirebaseAnalytics.logEvent("admin_bulk_email", {});

      setError("");
      setToastMessage("");

      const emails = emailText.split(/[\r\n]+/);

      const body: BulkEmailBody = {
        template_name: templateName,
        emails: emails,
      };

      http
        .post<BulkEmailResponse>(`/v1/admin/bulkemail`, body)
        .then((result) => {
          setEmailText("");
          setToastMessage(result.data.result);
        })
        .catch((e) => {
          setError(e.message);
        });
    },
    [templateName, emailText]
  );

  return (
    <PageLayout showEnvironment={true}>
      <Title title="Bulk Email" />
      <form onSubmit={(e) => submit(e)}>
        <div className="basis-full">
          <Input
            className="my-4"
            id="templateName"
            data-testid="templateName-input"
            label=""
            inputProps={{
              value: templateName,
              onChange: (e: any) => setTemplateName(e.target.value), //TODO - VALIDATE
            }}
            type="text"
            placeholder="Template Name"
          />
        </div>
        <div className="basis-full">
          <Input
            className="my-4"
            id="emailText"
            data-testid="emailText-input"
            label=""
            inputProps={{
              value: emailText,
              onChange: (e: any) => setEmailText(e.target.value),
            }}
            type="text"
            placeholder="Paste emails here (one email per line)"
            useTextArea={true}
          />
        </div>
        <Button
          type="submit"
          disabled={templateName === "" || emailText === ""}
          data-testid="submit"
        >
          Send Emails
        </Button>
        <ErrorMessage isText error={error} />
        <IonToast
          color="success"
          isOpen={!!toastMessage.length}
          onDidDismiss={() => setToastMessage("")}
          message={toastMessage}
          duration={5000}
        />
      </form>
    </PageLayout>
  );
};

export default BulkEmail;
